import { initializeApp  } from "@firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore, collection, doc} from 'firebase/firestore'
import { getStorage} from 'firebase/storage'
import {getFunctions} from 'firebase/functions'

let config  = {
  apiKey: "AIzaSyAdSuXfz0pBrFcbMtfxvff03nCvWhRotUI",
  authDomain: "razacreativaperu.firebaseapp.com",
  projectId: "razacreativaperu",
  storageBucket: "razacreativaperu.appspot.com",
  messagingSenderId: "62666119261",
  appId: "1:62666119261:web:4879b45b0c8c76902195b4",
  measurementId: "G-G0FDRSZCEP"
};

const firebase = initializeApp(config)

const firestore = getFirestore()
const auth = getAuth()
const functions = getFunctions()
const storage = getStorage()
const ColeccionLanding = doc(firestore, 'Landing/Contenido')

const ColeccionLandingImagenes = doc(firestore, 'Landing/Imágenes')
const Colección_blog = collection(firestore, 'Landing/Contenido/Blog')
const Colección_etiquetas = collection(firestore, 'Landing/Contenido/Etiquetas')


export {
  functions,
  auth,
  storage,
  firebase,
  ColeccionLanding,
  ColeccionLandingImagenes,
  Colección_blog,
  Colección_etiquetas,
}
