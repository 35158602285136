<template lang='pug'>
  v-responsive.pa-0(fluid )  
    v-app
      v-snackbar( v-model="Ayudas.mostrar_snackbar" :timeout='3000') 
        span {{ Ayudas.mensaje_de_snackbar }}
        v-btn( color='primario' text @click='Ayudas.mostrar_snackbar=false' ) Cerrar
      v-overlay( v-model='cargando' color='#dcf9f8')
        img( :src='loading' height='150px') 
      router-view(v-if='!cargando')

</template>
<script>
import loading from './assets/loading.gif'
import { mapActions, mapState } from 'vuex'
export default {
  data(){
    return{
      cargando: true,
      loading,
    }
  },
  created(){
    this.obtener_datos()
    this.obtener_entradas()
    this.obtener_etiquetas()
  },
  watch:{
    Contenido(){
      setTimeout(() => {
        this.cargando= false
      }, 3000)
    }
  },
  computed:{
    ...mapState({
      Ayudas : ({Ayudas}) => Ayudas,
    }),
  },
  methods: {
    ...mapActions({
      obtener_datos: 'Landing_set/obtener',
      obtener_entradas: 'Blog/obtener_todos',
      obtener_etiquetas: 'Etiquetas/obtener_todos',
    }),
  },
}
</script>
<style lang="sass" scoped>
.fade-enter-from, .fade-leave-to
    opacity: 0
.fade-enter-active, .fade-leave-active
    transition: opacity 0.5s ease-out
</style>