import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'es'
    },
  },
})
export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    lang: {
        locales: { es },
        current: 'es'
    },
    theme: {
      themes: {
        light: {
          primario: '#e62e4b',
          celeste: '#b6e8ef',
          celeste_secundario: '#59dde2',
          celeste_terciario: '#3cced5',
          fondo: '#f4feff',
          lado_izquierdo: '#dcf9f8',
          lado_derecho: '#c6e4e5',
          gris: '#6CADA5',
          color_izquiero_footer: "#D3F1F2"
        },
      },
    }
})