import Vue from 'vue'
import Vuex from 'vuex'
import {httpsCallable, getFunctions} from 'firebase/functions'
Vue.use(Vuex)
export default {
    namespaced:true,
    actions: {
        enviar_correo_electronico:  (context, {titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})=>{
            return new Promise((resolve, rejected)=>{
                const functions = getFunctions()
                try {
                    const _enviar_correo = httpsCallable(functions, 'correo_electronico-enviar') 
                     _enviar_correo({titulo, correo_electronico, cuerpo, asunto, configuracion_de_correo})
                     .then(()=> resolve())
                } catch (error) {
                    rejected(error)
                }
            })
        },
    },
}
