<template lang="pug">
v-container.pa-0(fluid)
    Boton_whatsapp
    Navegador
    transition(mode='out-in' 
        enter-active-class='animate__animated animate__fadeInLeft'
        leave-active-class='animate__animated animate__fadeOutLeft')
        router-view
    v-main
        video(width='100%' autoplay muted loop playsinline webkit-playsinline)
            source(:src='video')
    v-main(style='padding-left: 7%; padding-right: 7%; padding-top: 2%').fondo
        Servicios#servicios
        QuienesSomos#nosotros
        Cotizacion#contacto
    .portada(v-if='Cotizacion')
        v-img(:src='Cotizacion.imagen' max-height='260px' style='margin-top: 130px').d-none.d-md-block
            .texto(style='position: absolute; top: 24%; left: 0; right: 0; margin-left: auto; margin-right: auto; width: 650px; text-align: center')
                h2(style='line-height: 23px' v-html='Cotizacion.texto_pc').white--text.font-weight-light
                h2(style='line-height: 23px' v-html='Cotizacion.texto_pc_2').white--text
        v-img(:src='Cotizacion.imagen_movil').d-md-none
            .texto(style='position: absolute; top: 30%; left: 0; right: 0; margin-left: auto; margin-right: auto; width: 330px; text-align: center')
                h3( v-html='Cotizacion.texto_movil_banner').white--text.font-weight-light
               
    Pie_de_pagina
</template>
<script>
import video from '../../assets/video.webm'
import { mapState } from 'vuex'
export default {
    data: ()=>({
        video,
    }),
    computed: {
        ruta_actual(){
            return this.$route.name
        },
        ...mapState({
            Cargando: ({Ayudas}) => Ayudas.cargando,
        }),
    },
    components: {
        Navegador: ()=>import('./componentes/navegador.vue'),
        Servicios: ()=>import('./vistas/servicios.vue'),
        QuienesSomos: ()=>import('./vistas/quienesSomos.vue'),
        Cotizacion: ()=>import('./vistas/cotizacion.vue'),
        Boton_whatsapp: ()=>import('./componentes/botón_whatsApp.vue'),
        Pie_de_pagina: ()=>import('./componentes/pie_de_página.vue'),
        Bloque_blanco: ()=>import('./componentes/bloque_blanco.vue'),
        /*
        Contenedor_contacto: ()=>import('./componentes/contenedor_contacto.vue'),
        Blog_container: ()=>import('./componentes/novedades_container.vue'),
        */
    }
}
</script>
<style lang="sass" scoped>
.fade-enter, .fade-leave-to
    opacity: 0
    transform: translateX(2em)
.fade-enter-active, .fade-leave-active
    transition: all .3s ease-out
</style>
<style>
video::-webkit-media-controls-start-playback-button {
    display: none;
}
</style>